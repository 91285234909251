import React, { useState } from "react"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"
import useWindowWidth from "../components/utils/useWindowWidth"

import styled from "styled-components"
import PropTypes from "prop-types"

import {
  RGtheme,
  moduleSpacing,
  device,
  gridGutter,
  spacers,
} from "./utils/designsystem"
import { StyledDash } from "./Dash"

// Section Header - gets passed title as child, has prop boolean 'hasBottomPadding'

const HeaderFrame = styled(motion.div)`
  width: 100%;
  padding-bottom: ${spacers["24"].rem};

  grid-gap: ${gridGutter.mobileS.default};
  @media ${device.mobileS} {
    display: flex;
    flex-direction: column;

    grid-gap: ${gridGutter.mobileS.rem};
  }

  @media ${device.tablet} {
    display: flex;
    flex-direction: row;
    grid-gap: ${gridGutter.tablet.rem};
    padding-bottom: ${props =>
      props.hasBottomPadding ? spacers["96"].px : "0px"};
  }
`
const StyledHeaderFrame = styled(props => <HeaderFrame {...props} />)`
  margin-bottom: ${moduleSpacing.default};

  @media ${device.mobileS} {
    margin-bottom: ${moduleSpacing.mobileS};
  }
  @media ${device.tablet} {
    margin-bottom: ${moduleSpacing.tablet};
  }
`

const StyledHeader = styled(motion.div)`
  font-size: ${RGtheme.typeStyles.mHeader.fontsize};
  line-height: ${RGtheme.typeStyles.mHeader.lineheight};

  @media ${device.tablet} {
    font-size: ${RGtheme.typeStyles.dHeader.fontsize};
    line-height: ${RGtheme.typeStyles.dHeader.lineheight};
  }
`

const HeaderFrameVariants = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
}

const StyledDashDesktopVariants = {
  visible: {
    width: "240px",
    opacity: 1,
  },
  hidden: {
    width: "0px",
    opacity: 0,
  },
}

const StyledDashMobileVariants = {
  visible: {
    width: "100%",
    opacity: 1,
  },
  hidden: {
    width: "0px",
    opacity: 0,
  },
}

function SectionHeader(props) {

  const [ref, inView] = useInView({
    threshold: 0.8,
    triggerOnce: true,
  })

  const size = useWindowWidth();

  const [currentWidth, setCurrentWidth] = useState()

  const isDesktop = size.width > 768
  console.log("isDesktop", isDesktop, size.width)

  if (currentWidth !== currentWidth) {
    setCurrentWidth(size.width)
  }

  let ResponsiveVariant = isDesktop ? StyledDashDesktopVariants : StyledDashMobileVariants;

  return (
    <StyledHeaderFrame frameWidth={size.width} hasBottomPadding={props.hasBottomPadding ? true : false}>
      <StyledDash
        ref={ref}
        frameWidth={size.width}
        inital={"hidden"}
        animate={inView ? "visible" : "hidden"}
        variants={ResponsiveVariant}
        transition={{ duration: 1, type: "tween", ease: "easeOut" }}
      ></StyledDash>
      <StyledHeader
        ref={ref}
        frameWidth={size.width}
        initial={"hidden"}
        animate={inView ? "visible" : "hidden"}
        variants={HeaderFrameVariants}
        transition={{ delay: 0.8, duration: 1, type: "tween", ease: "easeIn" }}
      >
        {props.children}
      </StyledHeader>
    </StyledHeaderFrame>
  )
}
export default SectionHeader

SectionHeader.propTypes = {
  hasBottomPadding: PropTypes.bool,
  children: PropTypes.string,
}

import React from "react"
import { device, RGtheme, spacers } from "../components/utils/designsystem"
import styled from "styled-components"
import PropTypes from "prop-types"

const StyledText = styled.div`
  font-size: ${RGtheme.typeStyles.mBody.fontsize};
  line-height: ${RGtheme.typeStyles.mBody.lineHeight};

  padding-top: ${spacers["16"].px};
  padding-bottom: ${spacers["32"].px};

  @media ${device.laptop} {
    font-size: ${RGtheme.typeStyles.dBody.fontsize};
    line-height: ${RGtheme.typeStyles.dBody.lineHeight};

    padding-top: ${spacers["32"].px};
    padding-bottom: ${spacers["48"].px};
  }
`

const TextWithHeader = styled.div`
  font-size: ${RGtheme.typeStyles.dBody.fontsize};
  line-height: ${RGtheme.typeStyles.dBody.lineHeight};

  padding: 56px 0 148px 0;

  @media ${device.default} {
    display: grid;
    grid-template-columns: 1fr;
  }

  @media ${device.laptop} {
    display: grid;
    grid-template-columns: 1fr 3fr;
  }
`

export default function InlineText(props) {
  return props.header ? (
    <TextWithHeader>
      <p>{props.header}</p>
      <p>{props.children}</p>
    </TextWithHeader>
  ) : (
    <StyledText>
      {props.children ? props.children : "Error - no content"}
    </StyledText>
  )
}

InlineText.propTypes = {
  children: PropTypes.string.isRequired,
  header: PropTypes.string,
}

import React, { Component } from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import { device, fontScale, spacers, RGtheme } from "./utils/designsystem"
import PropTypes from "prop-types"
import Typewriter from "typewriter-effect"

import "../styles/index.css"

const HeroFrame = styled(props => <BackgroundImage {...props} />)`
  &::before,
  &::after {
    display: none !important;
    @media ${device.laptop} {
      display: flex !important;
    }
  }
  width: 100%;
  height: 100vh;
  min-height: 480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const TextFrame = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${spacers["40"].px};
`

const HeaderText = styled.h1`
  display: inline-block;

  font-size: ${RGtheme.typeStyles.mBody.fontsize};
  line-height: ${RGtheme.typeStyles.mBody.lineHeight};

  padding-bottom: ${spacers["8"].px};
`

const SubheadText = styled.h2`
  font-size: ${RGtheme.typeStyles.mJumbo.fontsize};
  line-height: ${RGtheme.typeStyles.mJumbo.lineHeight};

  @media ${device.laptop} {
    font-size: ${RGtheme.typeStyles.dJumbo.fontsize};
    line-height: ${RGtheme.typeStyles.dJumbo.lineHeight};
    width: 40%;
  }
  @media ${device.desktop} {
    font-size: ${fontScale["56"].px};
  }
`

export default class HomepageHero extends Component {
  render() {
    let titleArray = []
    this.props.titles.map(item => titleArray.push(item.title))
    return (
      <HeroFrame
        fluid={this.props.imageData}
        fadeIn={true}
        alt="Welcome"
        className=""
        objectFit="cover"
      >
        <TextFrame>
          <HeaderText>{this.props.name}</HeaderText>
          <SubheadText>
            <Typewriter
              options={{
                strings: titleArray,
                autoStart: true,
                cursor: "|",
                pauseFor: "2000",
                delay: "natural",
                deleteSpeed: "natural",
                loop: true,
              }}
            />
            {this.props.title}
          </SubheadText>
        </TextFrame>
      </HeroFrame>
    )
  }
}

HomepageHero.propTypes = {
  imageData: PropTypes.array,
  name: PropTypes.string,
  titles: PropTypes.array,
}

import React, { Component } from "react"
import styled from "styled-components"
import PropTypes from 'prop-types'

import { RGtheme, device } from "../components/utils/designsystem"

const StyledSpacer = styled.div`
  border: ${props => props.showFill ? "1px solid" : "none"};
  background: ${props => props.showFill ? "red" : "none"};
  height: ${RGtheme.moduleSpacing.mobileS.px};
  width: ${RGtheme.moduleSpacing.mobileS.px};

  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${device.mobileM} {
    height: ${RGtheme.moduleSpacing.mobileM};
    width: ${RGtheme.moduleSpacing.mobileM};
  }
  @media ${device.mobileL} {
    height: ${RGtheme.moduleSpacing.mobileL};
    width: ${RGtheme.moduleSpacing.mobileL};
  }
  @media ${device.tablet} {
    height: ${RGtheme.moduleSpacing.tablet};
    width: ${RGtheme.moduleSpacing.tablet};
  }
  @media ${device.laptop} {
    height: ${RGtheme.moduleSpacing.laptop};
    width: ${RGtheme.moduleSpacing.laptop};
  }
  @media ${device.laptopL} {
    height: ${RGtheme.moduleSpacing.laptopL};
    width: ${RGtheme.moduleSpacing.laptopL};
  }
  @media ${device.desktop} {
    height: ${RGtheme.moduleSpacing.desktop};
    width: ${RGtheme.moduleSpacing.desktop};
  }
`;

export default class ResponsiveSpacer extends Component {
  render() {
    return <StyledSpacer {...this.props}></StyledSpacer>
  }
}

ResponsiveSpacer.propTypes = {
  showFill: PropTypes.bool
}
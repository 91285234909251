import React from "react"
import { graphql } from "gatsby"

import AppLayoutTemplate from "../frames/MainLayout"
import ProjectsGrid from "../components/ProjectGrid"
import HomepageHero from "../components/HomepageHero"
import SectionHeader from "../components/SectionHeader"

import ResponsiveSpacer from "../components/ResponsiveSpacer"
import InlineText from "../components/InlineText"
import ClientList from "../components/ClientList"
import ViewportLabel from "../components/ViewportLabel"

import { device } from "../components/utils/designsystem"
import { ApplyLayoutFrame as SnapEdgesToPageGrid } from "../frames/ResponsiveModuleContainer"


const IndexPage = ({ data }) => {
  const heroBackgroundSharpStack = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `${device.laptop}`,
    },
  ]


  return (
    <AppLayoutTemplate>
      <SnapEdgesToPageGrid>
        <HomepageHero
          imageData={heroBackgroundSharpStack}
          name={data.dataJson.meta.name}
          titles={data.dataJson.meta.titles}
        />

        <section>
          <SectionHeader>Selected Projects</SectionHeader>

          <ProjectsGrid />

          <InlineText>
            *UX documentation & prototyping samples available upon request
          </InlineText>
        </section>

        <ResponsiveSpacer showFill={false} />

        <section>
          <SectionHeader>Past Clients</SectionHeader>
          <ClientList data={data.dataJson.About.clients} />
        </section>

        <ResponsiveSpacer showFill={false} />
      </SnapEdgesToPageGrid>

      <ViewportLabel left>Portfolio</ViewportLabel>
      <ViewportLabel right>Portfolio</ViewportLabel>
  
    </AppLayoutTemplate>
  )
}

export const query = graphql`
  query IndexPageQuery {
    dataJson {
      meta {
        titles {
          title
        }
        name
        description
      }
      About {
        clients {
          name
          displayName
          category
        }
      }
    }
    mobileImage: file(relativePath: { eq: "blank-hero.png" }) {
      childImageSharp {
        id
        fluid(jpegQuality: 100, maxWidth: 768) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    desktopImage: file(relativePath: { eq: "Profile-Banner.05-resize.jpg" }) {
      childImageSharp {
        id
        fluid(jpegQuality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
export default IndexPage

import React, { Component } from "react"
import styled from "styled-components"

import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

import {
  device,
  RGtheme,
  spacers,
  gridGutter,
} from "../components/utils/designsystem"

const Wrapper = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;

  font-size: ${RGtheme.typeStyles.mBody.fontsize};
  line-height: ${RGtheme.typeStyles.mBody.lineHeight};

  grid-gap: ${gridGutter.default.rem};

  @media ${device.mobileS} {
    grid-gap: ${gridGutter.mobileS.rem};
  }
  @media ${device.mobileM} {
    grid-gap: ${gridGutter.mobileM.rem};
  }
  @media ${device.tablet} {
    grid-gap: ${gridGutter.tablet.rem};
  }
  @media ${device.laptop} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: ${gridGutter.laptop.rem};

    font-size: ${RGtheme.typeStyles.dBody.fontsize};
    line-height: ${RGtheme.typeStyles.dBody.lineHeight};
  }
`

const ClientListItem = styled(motion.p)`
  border-bottom: ${RGtheme.strokes["2"].solid};
  padding-top: ${spacers["32"].px};
  padding-bottom: ${spacers["16"].px};
`

const RowVariants = {
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      ease: "easeIn",
    }
  },
  hidden: {
    opacity: 0,
  },
}

const ClientList = (props) => {
 
    const [ref, inView] = useInView({
      threshold: 0.6,
      triggerOnce: true,
    })

    return (
      <Wrapper
        ref={ref}
        inital={"hidden"}
        animate={inView ? "visible" : "hidden"}
        variants={RowVariants}
      >
        {props.data.reverse().map((item, index) => (
          <ClientListItem
            ref={ref}
            variants={RowVariants}
            key={index}
          >
            {item.displayName}
          </ClientListItem>
        ))}
      </Wrapper>
    )
}

export default ClientList
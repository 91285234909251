import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"

import { useStaticQuery, graphql } from "gatsby"
import { device, gridGutter } from "./utils/designsystem"

import ProjectsGridItem from "../components/ProjectGridItem"

const StyledGalleryGrid = styled.section`
  background-color: #ececec;
  display: grid;
  grid-template-columns: 1fr;

  grid-gap: ${gridGutter.default.rem};

  @media ${device.tablet} {
    background-color: #ececec;
    display: grid;
    grid-template-columns: 1fr 1fr;

    grid-gap: ${gridGutter.tablet.rem};
  }
  @media ${device.laptop} {
    grid-gap: ${gridGutter.laptop.rem};
  }
`

export default function ProjectsGrid() {
  const useProjectData = useStaticQuery(graphql`
    query ProjectDataQuery {
      dataJson {
        Projects {
          slug
          isVisible
          display {
            link_name
            category
          }
          assets {
            project_thumbnail_url
            project_thumbnail_rollover_url
            project_hero_image_url
          }
        }
      }
      allFile(
        filter: { relativePath: { regex: "/cover*/" } }
        sort: { fields: relativePath }
      ) {
        edges {
          node {
            dir
            name
            base
            childImageSharp {
              fluid(maxWidth: 840, pngQuality: 10, jpegQuality: 95) {
                ...GatsbyImageSharpFluid
              }
            }
            relativeDirectory
            relativePath
          }
        }
      }
    }
  `)

  return (
    <StyledGalleryGrid className="rg-gallery--grid">
      {useProjectData.dataJson.Projects.map(
        (projectitem, projectindex, pArray) =>
          projectitem.isVisible ? (
            <ProjectsGridItem
              key={projectindex}
              pID={projectindex}
              pSlug={projectitem.slug}
              pLinkName={projectitem.display.link_name}
              pCategory={projectitem.display.category}
              pThumbHoverURL={projectitem.assets.project_thumbnail_rollover_url}
              coverImageNodes={useProjectData.allFile.edges.filter(
                item =>
                  item.node.relativeDirectory.startsWith(projectitem.slug) &&
                  !item.node.relativePath.endsWith("-hover.png")
              )}
              coverImageHoverNodes={useProjectData.allFile.edges.filter(
                item =>
                  item.node.base ===
                  projectitem.assets.project_thumbnail_rollover_url
              )}
            />
          ) : null /*dont show tile if project visibility is hidden*/
      )}
    </StyledGalleryGrid>
  )
}

import React from "react"

import { Link as GatsbyLink } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"
import PropTypes from "prop-types"
import { motion } from "framer-motion"

import { RGtheme, device, spacers } from "./utils/designsystem"

const ApplyFocusAnimation = styled(motion.div)``

const StyledGatsbyLink = styled(props => <GatsbyLink {...props} />)`
  height: 100%;
  width: 100%;
`

const StyledImageFrame = styled(props => <BackgroundImage {...props} />)`
  height: 420px;
  width: 100%;
  overflow: hidden;

  @media ${device.mobileS} {
  }
`

const StyledHoverImage = styled(props => <StyledImageFrame {...props} />)`
  z-index: 10;
  /* 
  old visibilty toggle for hover image - changed to motion
  &:before,
  &:after {
    visibility: hidden;
  }
  &:hover {
    &:before,
    &:after {
      visibility: visible;
    }
  } */
`

const TextLabel = styled.p`
  font-size: ${RGtheme.typeStyles.mBody.fontsize};
  line-height: ${RGtheme.typeStyles.mBody.lineHeight};

  @media ${device.laptop} {
    font-size: ${RGtheme.typeStyles.dBody.fontsize};
    line-height: ${RGtheme.typeStyles.dBody.lineHeight};
  }

  padding-top: ${spacers["16"].px};
  padding-bottom: ${spacers["16"].px};
`

const ApplyZoom = styled(motion.div)`
  height: 100%;
  width: 100%;
`

class ProjectsGridItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hoverActive: false }
    this.toggleHover = this.toggleHover.bind(this)
  }

  toggleHover = () => {
    console.log("TRIGGERED Hover")

    this.setState(prevState => ({
      hoverActive: prevState.hoverActive ? false : true,
    }))
  }
  render() {
    const slug = this.props.pSlug
    const url = "/portfolio/" + slug

    const coverImg = this.props.coverImageNodes[0]
    const hoverImg = this.props.coverImageHoverNodes[0]

    return (
      <ApplyFocusAnimation
        onMouseOver={this.toggleHover}
        onMouseOut={this.toggleHover}
        initial={{
          scale: 1.0,
          zIndex: 5,
        }}
        whileHover={{
          scale: 1.02,
          zIndex: 10,
        }}
        transition={{
          duration: 0.8,
          type: "spring",
          bounce: 0.5,
        }}
      >
        <StyledGatsbyLink to={url}>
          <StyledImageFrame
            tag="div"
            fluid={coverImg.node.childImageSharp.fluid}
            alt={this.props.pLinkName}
          >
            <ApplyZoom
              initial={{
                opacity: 0,
              }}
              whileHover={{
                opacity: 1,
              }}
              transition={{ duration: 0.6, type: "tween", ease: "easeOut" }}
            >
              <StyledHoverImage
                fluid={hoverImg.node.childImageSharp.fluid}
                alt={this.props.pLinkName}
              ></StyledHoverImage>
            </ApplyZoom>
          </StyledImageFrame>

          <TextLabel>{this.props.pLinkName} — {this.props.pCategory}</TextLabel>
        </StyledGatsbyLink>
      </ApplyFocusAnimation>
    )
  }
}

export default ProjectsGridItem

ProjectsGridItem.propTypes = {
  pID: PropTypes.number,
  pSlug: PropTypes.string,
  pCategory: PropTypes.string,
  pLinkName: PropTypes.string,
  pThumbHoverURL: PropTypes.string,
  project_thumbnail_rollover_url: PropTypes.string,
  coverImageNodes: PropTypes.array,
  coverImageHoverNodes: PropTypes.array,
}
